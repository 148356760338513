import React, { Component } from 'react';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import * as ComponentUtils from 'utils/component';
import { pagePaths } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as Toast from 'utils/toast';
import * as EventTypes from '../data/eventType';

class Page extends Component {
    componentDidMount() {
        const { params, location, orderSessionData, orderService } = this.props;
        const { shareCart } = orderSessionData;

        const urlParams = new URLSearchParams(location.search);
        const urlPaymentStatus = urlParams.get('paymentStatus');

        if (params.eventType === EventTypes.FromPayment && urlPaymentStatus) {
            // Redirect from payment action
            this.props.blockUI("Redirecting ...");

            const routeOptions = {
                paymentStatus: urlPaymentStatus
            };

            // CX-TODO : Consider to use replace current redirect page, and use react routing technique to show the payment status message instead
            // Objective to achieve : When click on back button, won't keep showing the payment status toast message
            orderService.startOrder(shareCart, false, routeOptions)
                .then(() => {
                    this.props.unblockUI();
                })
                .catch(errorMessage => {
                    this.props.unblockUI();
                    Toast.showErrorMessage(errorMessage);
                });
        } else {
            // Redirect to home page (default)
            RoutesUtils.redirectToPage(pagePaths.Home);
        }
    }

    render() {
        return <></>;
    }
}

const hocs = [
    withBlockUI,
    withOrderService,
    withRouter
];

export default ComponentUtils.compose(hocs)(Page);