import * as OrderStatusConstants from './orderStatusConstant';

const orderStatusText = [
    {
        text: 'Order Pending Acceptance',
        icon: 'fas fa-circle-notch fa-spin',
        color: 'black',
        background: '#dce2e2',
        value: OrderStatusConstants.WaitingToAccept
    },
    {
        text: 'Pending for Payment',
        icon: 'fas fa-circle-notch fa-spin',
        color: 'black',
        background: '#dce2e2',
        value: OrderStatusConstants.PendingPayment
    },
    {
        text: 'Order Accepted',
        icon: 'fas fa-check-circle',
        color: 'white',
        background: '#04ad20',
        value: OrderStatusConstants.Accepted
    },
    {
        text: 'Order Rejected',
        icon: 'fas fa-times-circle',
        color: 'white',
        background: '#b70508',
        value: OrderStatusConstants.Rejected
    },
    {
        text: 'Order Cancelled',
        icon: 'fas fa-ban',
        color: 'white',
        background: '#ff6e40',
        value: OrderStatusConstants.Cancelled
    },
    {
        text: 'Payment Accepted',
        icon: 'fas fa-check-circle',
        color: 'white',
        background: '#44a8ff',
        value: OrderStatusConstants.Paid
    }
];

export function getOrderStatusItem(value) {
    const item = orderStatusText.find(x => x.value === value);
    return item ? item : null;
}

export function getOrderStatusText(value) {
    const item = getOrderStatusItem(value);
    return item ? item.text : '';
}