import React, { useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input/input';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import * as DocumentIdConstants from 'data/documentIdConstant';
import * as EventUtils from 'utils/eventUtils';

const BillingDetailsDialog = ({ billingEmail, billingName, billingContact, saveBillingDetails, closeDialog, handleChange, handleSubmit }) => {
    const [errors, setErrors] = useState({});

    function onSubmit(e) {
        EventUtils.preventDefault(e);

        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            handleSubmit();
        }
    }

    function validateForm() {
        // Validate form inputs
        const validationErrors = {};

        // - Validate billing email
        if (!billingEmail) {
            validationErrors.billingEmail = 'Email address is required.';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(billingEmail);

            if (!isValidEmail) {
                validationErrors.billingEmail = 'Please enter a valid email address.';
            }
        }

        // - Validate payer name
        if (!billingName) {
            validationErrors.billingName = 'Name is required.';
        } else if (billingName.trim().length <= 5) {
            validationErrors.billingName = 'Name must be more than 5 characters.';
        }

        // - Validate billing contact
        if (!billingContact) {
            validationErrors.billingContact = 'Phone number is required.';
        } else if (!isPossiblePhoneNumber(billingContact) || !isValidPhoneNumber(billingContact)) {
            validationErrors.billingContact = 'Please enter a valid phone number.';
        }

        return validationErrors;
    }

    return (
        <BottomSheetDialog
            title="Billing Details"
            titleIconText="Payment receipt will be sent to the email provided below."
            isOpen
            closeDialog={closeDialog}
            footer={
                <div
                    className="apps-general-box-shadow d-flex justify-content-center py-3"
                    style={{ borderTop: '1px solid var(--grey-300)' }}
                >
                    <Button
                        className="col-6 py-2"
                        primary
                        submit
                        form={DocumentIdConstants.UIOrderBillingDetailsDialogForm}
                    >
                        Proceed
                    </Button>
                </div>
            }
        >
            <form
                id={DocumentIdConstants.UIOrderBillingDetailsDialogForm}
                className="px-4 my-3"
                onSubmit={onSubmit}
                noValidate
            >
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text fas fa-envelope" />
                    </div>

                    <input
                        className={`form-control${errors.billingEmail ? ' is-invalid' : ''}`}
                        type="email"
                        placeholder="Email address"
                        maxLength={30}
                        value={billingEmail}
                        onChange={e => handleChange({ billingEmail: e.target.value })}
                    />

                    {errors.billingEmail && <div className="invalid-feedback">{errors.billingEmail}</div>}
                </div>

                <div className="input-group mt-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text fas fa-user" />
                    </div>

                    <input
                        className={`form-control${errors.billingName ? ' is-invalid' : ''}`}
                        type="text"
                        placeholder="Name"
                        maxLength={30}
                        value={billingName}
                        onChange={e => handleChange({ billingName: e.target.value })}
                    />

                    {errors.billingName && <div className="invalid-feedback">{errors.billingName}</div>}
                </div>

                <div className="input-group mt-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text fas fa-phone" />
                    </div>

                    <PhoneInput
                        className={`form-control${errors.billingContact ? ' is-invalid' : ''}`}
                        country='MY'
                        placeholder="Phone number"
                        international
                        withCountryCallingCode
                        value={billingContact}
                        onChange={value => handleChange({ billingContact: value || '' })}
                    />

                    {errors.billingContact && <div className="invalid-feedback">{errors.billingContact}</div>}
                </div>

                <div className="form-group mt-4">
                    <div className="form-check">
                        <input
                            id={DocumentIdConstants.UIOrderBillingDetailsDialogSaveBillingDetailsInput}
                            className="form-check-input"
                            type="checkbox"
                            checked={saveBillingDetails}
                            onChange={e => handleChange({ saveBillingDetails: e.target.checked })}
                        />

                        <label className="form-check-label text-muted" htmlFor={DocumentIdConstants.UIOrderBillingDetailsDialogSaveBillingDetailsInput}>
                            Save my details for next purchase
                        </label>
                    </div>
                </div>
            </form>
        </BottomSheetDialog>
    );
}

export default React.memo(BillingDetailsDialog);