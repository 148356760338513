import React, { Component } from 'react';
import BackButton from 'components/BackButton';
import Dialog from 'components/Dialog';
import TabPanel from 'components/TabPanel';
import TabContent from 'components/TabContent';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withUserDeviceSettings from 'hocs/withUserDeviceSettings';
import * as ComponentUtils from 'utils/component';
import * as Toast from 'utils/toast';
import { InCartTabFooter } from './InCartTab/Footer';
import InCartTab from './InCartTab/InCartTab';
import { OrderedTabFooter } from './OrderedTab/Footer';
import OrderedTab from './OrderedTab/OrderedTab';

class OrderDialogInner extends Component {
    constructor(props) {
        super(props);

        const { tabKey, forwardedRef } = props;
        forwardedRef.current = this;

        this.state = {
            tabKey,
            orders: [],
            lastSyncOrderTimeStamp: null
        };

        this.tabRef = React.createRef();
        this.orderedTabSyncOrdersRef = React.createRef();

        this.syncOrdersTimer = null;

        this.onPageShow = this.onPageShow.bind(this);

        this.onTabChanged = this.onTabChanged.bind(this);
        this.syncOrders = this.syncOrders.bind(this);

        this.createSyncOrdersTimer = this.createSyncOrdersTimer.bind(this);
        this.deleteSyncOrdersTimer = this.deleteSyncOrdersTimer.bind(this);

        this.isInCartTab = this.isInCartTab.bind(this);
        this.isOrderedTab = this.isOrderedTab.bind(this);
    }

    componentDidMount() {
        this.syncOrders(false);
        window.addEventListener('pageshow', this.onPageShow);
    }

    componentWillUnmount() {
        this.deleteSyncOrdersTimer();
        window.removeEventListener('pageshow', this.onPageShow);
    }

    onPageShow() {
        // Note : This is necessary to handle cases where the user clicks the mobile back button after being redirected to the Payment Gateway.
        const blockUIEl = document.querySelector('.apps-component-block-ui-overlay');

        if (blockUIEl && blockUIEl.classList.contains('blocking')) {
            this.props.unblockUI();
        }
    }

    onTabChanged(tabKey) {
        const isChangingToOrderedTab = this.isOrderedTab(tabKey);
        const isCurrentlyNotInOrderedTab = !this.isOrderedTab(this.state.tabKey);

        if (isChangingToOrderedTab && isCurrentlyNotInOrderedTab) {
            this.syncOrders(false, true);
        }

        this.setState({ tabKey });
    }

    syncOrders(isTriggeredByTimer, showSpinAnimation = false) {
        const { orderService } = this.props;

        const spinClassName = 'fa-spin';

        const syncOrdersIconEl = this.orderedTabSyncOrdersRef.current;
        const syncOrdersIconElClassList = syncOrdersIconEl.classList;

        if (!isTriggeredByTimer) {
            this.deleteSyncOrdersTimer();
        }

        if (showSpinAnimation) {
            syncOrdersIconElClassList.add(spinClassName);
        }

        orderService.getOrders()
            .then(orders => {
                this.setState({
                    orders,
                    lastSyncOrderTimeStamp: new Date()
                });
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            })
            .finally(() => {
                if (!isTriggeredByTimer) {
                    this.createSyncOrdersTimer();
                }

                if (showSpinAnimation) {
                    syncOrdersIconElClassList.remove(spinClassName);
                }
            });
    }
    
    createSyncOrdersTimer() {
        // Create a timer that sync orders automatically every 5 seconds
        if (this.syncOrdersTimer !== null) return;

        this.syncOrdersTimer = setInterval(() => {
            // Note : tabKey must be accessed via the latest component state
            if (this.props.isPageVisible && this.props.isNetworkOnline && this.isOrderedTab(this.state.tabKey)) {
                this.syncOrders(true);
            }
        }, 5000);
    }

    deleteSyncOrdersTimer() {
        // Delete timer
        if (this.syncOrdersTimer === null) return;

        clearInterval(this.syncOrdersTimer);
        this.syncOrdersTimer = null;
    }

    isInCartTab(tabKey = this.state.tabKey) {
        return tabKey === 'order0';
    }

    isOrderedTab(tabKey = this.state.tabKey) {
        return tabKey === 'order1';
    }

    render() {
        const { cart, closeOrderDialog, confirmOrder, makePayment } = this.props;
        const { orders, lastSyncOrderTimeStamp } = this.state;

        return (
            <Dialog
                isOpen
                fullScreen
                footer={(
                    <>
                        {this.isInCartTab() && cart.hasAnyItems() && <InCartTabFooter confirmOrder={confirmOrder} />}
                        {this.isOrderedTab() && orders.length > 0 && <OrderedTabFooter orders={orders} />}
                    </>
                )}
            >
                <>
                    <div className="d-flex align-items-center justify-content-center position-sticky top-0 bg-white" style={{ height: '50px', zIndex: 5 }}>
                        <BackButton className="position-absolute ion-ios-arrow-back start-0 fs-1 ps-3" onClick={closeOrderDialog} />
                        <span className="fw-bold">Your Order Cart</span>
                    </div>

                    <TabContent
                        tabPrefix="order"
                        defaultTabKey={this.isOrderedTab() ? 'order1' : undefined}
                        top='50px'
                        height='45px'
                        justify
                        sticky
                        boxShadow
                        onTabChanged={this.onTabChanged}
                        ref={this.tabRef}
                    >
                        <TabPanel title="In Cart" noPadding>
                            <InCartTab closeOrderDialog={closeOrderDialog} />
                        </TabPanel>

                        <TabPanel title="Ordered" noPadding>
                            <OrderedTab
                                orders={orders}
                                lastSyncOrderTimeStamp={lastSyncOrderTimeStamp}
                                closeOrderDialog={closeOrderDialog}
                                makePayment={makePayment}
                                syncOrders={this.syncOrders}
                                ref={this.orderedTabSyncOrdersRef}
                            />
                        </TabPanel>
                    </TabContent>
                </>
            </Dialog>
        );
    }
}

const hocs = [
    withBlockUI,
    withOrderService,
    withUserDeviceSettings
];

const OrderDialogWithHocs = ComponentUtils.compose(hocs)(OrderDialogInner);

const OrderDialog = React.forwardRef((props, ref) => <OrderDialogWithHocs {...props} forwardedRef={ref} />);

export default React.memo(OrderDialog);