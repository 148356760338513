import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import BottomSheetDialog from 'components/BottomSheetDialog';
import * as DocumentIdConstants from 'data/documentIdConstant';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import withFormData from 'hocs/withFormData';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import Header from './Header';
import ImageDialog from './ImageDialog';
import MenuItemDetailPanel from './MenuItemDetailPanel';
import RemarkPanel from './RemarkPanel';
import SetMealCategoryPanel from './SetMealCategoryPanel';
import SetMealItemForm from './SetMealItemForm';
import TakeAwayPanel from './TakeAwayPanel';
import Footer from './Shared/Footer';
import ModifierCategoryPanel from './Shared/ModifierCategoryPanel';
import * as FormValidationConstants from '../data/formValidationConstant';

class EditForm extends Component {
    constructor(props) {
        super(props);

        const { data, menuItem, isSetMeal } = props;

        const configs = {};
        if (isSetMeal) {
            configs.categories = menuItem.setMeal.categories;
            configs.selectedItems = data.setMealItems;
            configs.itemInCategoryComparer = (item, category) => item.menuSetMealCategoryGuid === category.menuSetMealCategoryGuid;
            configs.hasAvailableOptionVerifier = undefined;
            configs.idSelector = category => category.menuSetMealCategoryGuid;
        } else {
            configs.categories = menuItem.modifierCategories;
            configs.selectedItems = data.modifierItems;
            configs.itemInCategoryComparer = (item, category) => item.menuModifierCategoryGuid === category.menuModifierCategoryGuid;
            configs.hasAvailableOptionVerifier = (items, selectedCategoryItems) => items.some(x => !x.isOutOfStock && !selectedCategoryItems.some(y => y.menuModifierItemGuid === x.menuModifierItemGuid))
            configs.idSelector = category => category.menuModifierCategoryGuid;
        }

        this.state = {
            showAppBarBg: false,
            activeEventKeys: this.buildActiveEventKeys(configs),
            setMealItemFormActiveEventKeys: [],
            setMealItemFormData: {},
            setMealItemFormMode: {},
            showSetMealItemForm: false,
            imageSrc: null,
            showImageDialog: false,
        };

        this.menuItemDetailPanel = React.createRef();

        this.accordionButtonRefs = {};
        this.setMealItemFormAccordionButtonRefs = {};

        this.toggleScrollListener = this.toggleScrollListener.bind(this);
        this.onScroll = this.onScroll.bind(this);

        // Accordion
        this.buildActiveEventKeys = this.buildActiveEventKeys.bind(this);
        this.onAccordionSelect = this.onAccordionSelect.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);

        // Data handling
        this.handleServiceTypeChange = this.handleServiceTypeChange.bind(this);
        this.handleCartItemDerivedValuesChanged = this.handleCartItemDerivedValuesChanged.bind(this);

        // Form submission
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);

        // CategoryPanel
        this.renderModifierCategoryPanel = this.renderModifierCategoryPanel.bind(this);
        this.renderSetMealCategoryPanel = this.renderSetMealCategoryPanel.bind(this);

        // SetMealItemForm
        this.openSetMealItemForm = this.openSetMealItemForm.bind(this);
        this.closeSetMealItemForm = this.closeSetMealItemForm.bind(this);
        this.addSetMealItem = this.addSetMealItem.bind(this);
        this.updateSetMealItem = this.updateSetMealItem.bind(this);
        this.saveSetMealItem = this.saveSetMealItem.bind(this);

        // ImageDialog
        this.openImageDialog = this.openImageDialog.bind(this);
        this.closeImageDialog = this.closeImageDialog.bind(this);
    }

    componentDidMount() {
        this.toggleScrollListener(modalBodyEl => modalBodyEl.addEventListener('scroll', this.onScroll));
    }

    componentWillUnmount() {
        this.toggleScrollListener(modalBodyEl => modalBodyEl.removeEventListener('scroll', this.onScroll));
    }

    // Methods
    toggleScrollListener(action) {
        if (this.props.data.image) {
            const dialogEl = document.querySelector(`#${DocumentIdConstants.UIMenuItemEditFormDialog}`);
            const modalBodyEl = dialogEl.querySelector('.modal-body');

            action(modalBodyEl);
        }
    }

    onScroll(e) {
        const menuItemDetailPanelEl = this.menuItemDetailPanel.current;
        const showAppBarBg = menuItemDetailPanelEl.offsetTop - e.target.scrollTop < 50;

        if (this.state.showAppBarBg !== showAppBarBg) {
            this.setState({ showAppBarBg });
        }
    }

    // - Accordion
    buildActiveEventKeys(configs) {
        // Build ActiveEventKeys for ModifierCategories / SetMealCategories
        const { categories, selectedItems, itemInCategoryComparer, hasAvailableOptionVerifier, idSelector } = configs;
        const activeEventKeys = [];

        for (const category of categories) {
            const selectedCategoryItems = selectedItems.filter(x => itemInCategoryComparer(x, category));
            const totalQty = selectedCategoryItems.reduce((total, item) => total + item.quantity, 0);

            const isNotReachedMaxQty = !category.maxQty || category.maxQty > totalQty;
            const hasAvailableOption = hasAvailableOptionVerifier ? hasAvailableOptionVerifier(category.items, selectedCategoryItems) : true;

            if (isNotReachedMaxQty && hasAvailableOption) {
                activeEventKeys.push(idSelector(category));
            }
        }

        return activeEventKeys;
    }

    onAccordionSelect(activeEventKeys) {
        const { showSetMealItemForm } = this.state;

        if (showSetMealItemForm) {
            this.setState({ setMealItemFormActiveEventKeys: activeEventKeys });
        } else {
            this.setState({ activeEventKeys });
        }
    }

    toggleAccordion(key) {
        const { showSetMealItemForm } = this.state;

        if (showSetMealItemForm) {
            this.setMealItemFormAccordionButtonRefs[key].current.click();
        } else {
            this.accordionButtonRefs[key].current.click();
        }
    }

    // - Data handling
    handleServiceTypeChange(checked) {
        this.props.handleChange({
            serviceType: checked ? ServiceTypeConstants.TakeAway : ServiceTypeConstants.DineIn
        });
    }

    handleCartItemDerivedValuesChanged(values) {
        // Handle changes that involves pricing
        const { cart, handleChange } = this.props;
        const data = { ...this.props.data, ...values };
        cart.sortCartItemChildren(data);
        cart.recalculateCartItemDerivedValues(data);
        handleChange(data);
    }

    // Form submission
    handleSubmit(e) {
        const { data, menuItem, isSetMeal } = this.props;
        EventUtils.preventDefault(e);

        const configs = {};

        // Build configs for SetMeal validation
        if (isSetMeal) {
            configs.categories = menuItem.setMeal.categories;
            configs.selectedItems = data.setMealItems;
            configs.itemInCategoryComparer = (selectedItem, category) => selectedItem.menuSetMealCategoryGuid === category.menuSetMealCategoryGuid;
            configs.idSelector = category => category.menuSetMealCategoryGuid;
        }
        // Build configs for MenuItem validation
        else {
            configs.categories = menuItem.modifierCategories;
            configs.selectedItems = data.modifierItems;
            configs.itemInCategoryComparer = (selectedItem, category) => selectedItem.menuModifierCategoryGuid === category.menuModifierCategoryGuid;
            configs.idSelector = category => category.menuModifierCategoryGuid;
        }

        const isValid = this.validate(configs);
        if (isValid) this.props.handleSubmit(e);
    }

    validate(configs) {
        // Validate submission for ModifierCategory / SetMealCategory
        const { showSetMealItemForm } = this.state;
        const { categories, selectedItems, itemInCategoryComparer, idSelector } = configs;

        let hasScrollIntoView = false;
        let isValid = true;

        for (const category of categories) {
            if (!category.minQty) continue;

            const totalSelectedQty = selectedItems.reduce((total, selectedItem) => {
                if (itemInCategoryComparer(selectedItem, category)) {
                    total += selectedItem.quantity;
                }

                return total;
            }, 0);

            // Handle invalid submission
            if (totalSelectedQty < category.minQty) {
                const id = idSelector(category);

                // - Get element
                const element = document.getElementById(id);

                // - Expand accordion
                const activeEventKeys = [];
                let toggleAccordion = null;

                if (showSetMealItemForm) {
                    activeEventKeys.push(...this.state.setMealItemFormActiveEventKeys);
                    toggleAccordion = () => this.setMealItemFormAccordionButtonRefs[id].current.click();
                } else {
                    activeEventKeys.push(...this.state.activeEventKeys);
                    toggleAccordion = () => this.accordionButtonRefs[id].current.click();
                }

                if (!activeEventKeys.includes(id)) {
                    // Note : Use setTimeout here to handle "cant open all accordion items at once" issue
                    setTimeout(() => toggleAccordion(), 0);
                }

                // - Scroll into view
                if (!hasScrollIntoView) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    hasScrollIntoView = true;
                }

                // - Display validator element
                const validatorElement = element.querySelector(`.${FormValidationConstants.InvalidFeedbackClass}`);
                validatorElement.style.display = 'flex';
                validatorElement.style.alignItems = 'center';

                // - Set isValid to false 
                if (isValid) isValid = false;
            }
        }

        return isValid;
    }

    // - CategoryPanel
    renderModifierCategoryPanel(modifierCategory) {
        const { data } = this.props;
        const { activeEventKeys } = this.state;
        const key = modifierCategory.menuModifierCategoryGuid;

        this.accordionButtonRefs[key] = React.createRef();

        return (
            <ModifierCategoryPanel
                key={key}
                modifierCategory={modifierCategory}
                cartItemModifierItems={data.modifierItems}
                activeEventKey={activeEventKeys.find(x => x === key) ?? null}
                toggleAccordion={this.toggleAccordion}
                openImageDialog={this.openImageDialog}
                handleModifierItemChange={this.handleCartItemDerivedValuesChanged}
                accordionButtonRef={this.accordionButtonRefs[key]}
            />
        );
    }

    renderSetMealCategoryPanel(setMealCategory) {
        const { data } = this.props;
        const { activeEventKeys } = this.state;
        const key = setMealCategory.menuSetMealCategoryGuid;

        this.accordionButtonRefs[key] = React.createRef();

        return (
            <SetMealCategoryPanel
                key={key}
                setMealCategory={setMealCategory}
                cartSetMealItems={data.setMealItems}
                activeEventKey={activeEventKeys.find(x => x === key) ?? null}
                toggleAccordion={this.toggleAccordion}
                openImageDialog={this.openImageDialog}
                handleSetMealItemChanged={this.handleCartItemDerivedValuesChanged}
                addSetMealItem={this.addSetMealItem}
                updateSetMealItem={this.updateSetMealItem}
                accordionButtonRef={this.accordionButtonRefs[key]}
            />
        );
    }

    // - SetMealItemForm
    setSetMealItemAddMode() {
        this.setState({ setMealItemFormMode: { isAdd: true } });
    }

    setSetMealItemUpdateMode() {
        this.setState({ setMealItemFormMode: { isUpdate: true } });
    }

    openSetMealItemForm() {
        this.setState({ showSetMealItemForm: true });
    }

    closeSetMealItemForm() {
        this.setState({ showSetMealItemForm: false });
    }

    addSetMealItem(setMealCategory, setMealItem, maxQty, resetSetMealCategory) {
        const { cart } = this.props;
        const cartSetMealItem = cart.buildCartSetMealItem(setMealCategory, setMealItem);
        cart.recalculateCartSetMealItemDerivedValues(cartSetMealItem);

        const configs = {
            categories: setMealItem.modifierCategories,
            selectedItems: cartSetMealItem.modifierItems,
            itemInCategoryComparer: (item, category) => item.menuModifierCategoryGuid === category.menuModifierCategoryGuid,
            hasAvailableOptionVerifier: (items, selectedCategoryItems) => items.some(x => !x.isOutOfStock && !selectedCategoryItems.some(y => y.menuModifierItemGuid === x.menuModifierItemGuid)),
            idSelector: category => category.menuModifierCategoryGuid
        };

        this.setState({
            setMealItemFormActiveEventKeys: this.buildActiveEventKeys(configs),
            setMealItemFormData: {
                setMealCategory,
                setMealItem,
                cartSetMealItem,
                maxQty,
                resetSetMealCategory
            }
        });

        this.setSetMealItemAddMode();
        this.openSetMealItemForm();
    }

    updateSetMealItem(setMealItem, cartSetMealItem, maxQty) {
        const configs = {
            categories: setMealItem.modifierCategories,
            selectedItems: cartSetMealItem.modifierItems,
            itemInCategoryComparer: (item, category) => item.menuModifierCategoryGuid === category.menuModifierCategoryGuid,
            hasAvailableOptionVerifier: (items, selectedCategoryItems) => items.some(x => !x.isOutOfStock && !selectedCategoryItems.some(y => y.menuModifierItemGuid === x.menuModifierItemGuid)),
            idSelector: category => category.menuModifierCategoryGuid
        };

        this.setState({
            setMealItemFormActiveEventKeys: this.buildActiveEventKeys(configs),
            setMealItemFormData: {
                setMealItem,
                cartSetMealItem,
                maxQty
            }
        });

        this.setSetMealItemUpdateMode();
        this.openSetMealItemForm();
    }

    saveSetMealItem(_, current) {
        const { data } = this.props;
        const { setMealItemFormData, setMealItemFormMode } = this.state;
        const { setMealItem, setMealCategory, resetSetMealCategory } = setMealItemFormData;

        const isValid = this.validate({
            categories: setMealItem.modifierCategories,
            selectedItems: current.modifierItems,
            itemInCategoryComparer: (selectedItem, category) => selectedItem.menuModifierCategoryGuid === category.menuModifierCategoryGuid,
            idSelector: category => category.menuModifierCategoryGuid
        });

        if (!isValid) return;

        if (setMealItemFormMode.isAdd) {
            this.handleCartItemDerivedValuesChanged({
                setMealItems: [
                    ...data.setMealItems.filter(x => resetSetMealCategory ? x.menuSetMealCategoryGuid !== setMealCategory.menuSetMealCategoryGuid : true),
                    { ...current }
                ]
            });
        } else {
            this.handleCartItemDerivedValuesChanged({
                setMealItems: [
                    ...data.setMealItems.filter(x => x.menuSetMealItemGuid !== current.menuSetMealItemGuid),
                    { ...current }
                ]
            });
        }

        this.closeSetMealItemForm();
    }

    // - ImageDialog
    openImageDialog(imageSrc) {
        this.setState({ showImageDialog: true, imageSrc });
    }

    closeImageDialog() {
        this.setState({ showImageDialog: false });
    }

    render() {
        const { orderSessionData, cart, data, menuItem, isAdd, isSetMeal, handleChange, handleCancel } = this.props;
        const { showAppBarBg, activeEventKeys, setMealItemFormActiveEventKeys, setMealItemFormData, setMealItemFormMode, showSetMealItemForm, imageSrc, showImageDialog } = this.state;
        const { orderServiceType } = orderSessionData;

        const isTakeAwayServiceType = orderServiceType === ServiceTypeConstants.TakeAway;
        const qty = cart.calculateMenuItemTotalQuantity(menuItem.menuItemGuid);
        const fromPrice = isSetMeal ? menuItem.setMeal.priceFrom : data.price;

        return (
            <>
                <BottomSheetDialog
                    id={DocumentIdConstants.UIMenuItemEditFormDialog}
                    isOpen
                    footer={
                        <Footer
                            qty={data.quantity}
                            price={data.subTotal}
                            form={DocumentIdConstants.UIMenuItemEditFormForm}
                            buttonText={isAdd ? 'Add To Cart' : 'Update Cart'}
                            handleMinus={() => this.handleCartItemDerivedValuesChanged({ quantity: data.quantity - 1 })}
                            handleAdd={() => this.handleCartItemDerivedValuesChanged({ quantity: data.quantity + 1 })}
                        />
                    }
                    closeDialog={handleCancel}
                >
                    <form id={DocumentIdConstants.UIMenuItemEditFormForm} onSubmit={this.handleSubmit}>
                        <Header
                            imageSrc={data.image}
                            name={menuItem.name}
                            displayCode={menuItem.displayCode}
                            showAppBarBg={showAppBarBg}
                            closeDialog={handleCancel}
                            openImageDialog={this.openImageDialog}
                        />

                        {!data.image &&
                            <div style={{ height: '50px' }} />
                        }

                        <MenuItemDetailPanel
                            data={data}
                            menuItem={menuItem}
                            fromPrice={fromPrice}
                            qty={qty}
                            handleServiceTypeChange={this.handleServiceTypeChange}
                            ref={this.menuItemDetailPanel}
                        />

                        {!isTakeAwayServiceType &&
                            <TakeAwayPanel
                                data={data}
                                handleServiceTypeChange={this.handleServiceTypeChange}
                            />
                        }

                        <Accordion
                            alwaysOpen
                            defaultActiveKey={activeEventKeys}
                            onSelect={this.onAccordionSelect}
                        >
                            {isSetMeal
                                ? menuItem.setMeal.categories.map(this.renderSetMealCategoryPanel)
                                : menuItem.modifierCategories.map(this.renderModifierCategoryPanel)
                            }
                        </Accordion>

                        <RemarkPanel
                            value={data.remark}
                            onValueChanged={remark => handleChange({ remark })}
                        />

                        {(menuItem.modifierCategories.length > 0 || isSetMeal) &&
                            <div style={{ height: '50px' }} />
                        }
                    </form>
                </BottomSheetDialog>

                {showSetMealItemForm &&
                    <SetMealItemForm
                        initialData={setMealItemFormData.cartSetMealItem}
                        activeEventKeys={setMealItemFormActiveEventKeys}
                        accordionButtonRefs={this.setMealItemFormAccordionButtonRefs}
                        setMealItem={setMealItemFormData.setMealItem}
                        maxQty={setMealItemFormData.maxQty}
                        isAdd={setMealItemFormMode.isAdd}
                        onAccordionSelect={this.onAccordionSelect}
                        toggleAccordion={this.toggleAccordion}
                        openImageDialog={this.openImageDialog}
                        onCancel={this.closeSetMealItemForm}
                        onSubmit={this.saveSetMealItem}
                    />
                }

                {showImageDialog &&
                    <ImageDialog
                        imageSrc={imageSrc}
                        closeDialog={this.closeImageDialog}
                    />
                }
            </>
        );
    }
}

const hocs = [
    withFormData,
    withOrderService
];

export default ComponentUtils.compose(hocs)(EditForm);