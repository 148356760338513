import React from 'react';
import classNames from 'classnames';

const Button = ({ submit, primary, secondary, success, warning, info, danger, light, link, outline, round, className, children, ...rest }) => {
    const classes = classNames('btn',
        {
            'btn-default': !outline && !primary && !secondary && !success && !warning && !info && !danger && !link,
            'btn-primary': !outline && primary,
            'btn-secondary': !outline && secondary,
            'btn-success': !outline && success,
            'btn-warning': !outline && warning,
            'btn-info': !outline && info,
            'btn-danger': !outline && danger,
            'btn-light': !outline && light,
            'btn-link': link,
            'btn-outline-default': outline && !primary && !secondary && !success && !warning && !info && !danger && !link,
            'btn-outline-primary': outline && primary,
            'btn-outline-secondary': outline && secondary,
            'btn-outline-success': outline && success,
            'btn-outline-warning': outline && warning,
            'btn-outline-info': outline && info,
            'btn-outline-danger': outline && danger,
            'btn-outline-light': outline && light,
            'btn-round': round
        },
        className
    );

    return (
        <button type={submit ? 'submit' : 'button'} className={classes}{...rest}>{children}</button>
    );
}

export default Button;