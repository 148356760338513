import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import HintText from 'components/HintText';
import Image from 'components/Image';
import RestaurantOperatingDayDisplay from 'Restaurant/components/RestaurantOperatingDayDisplay';

const AboutDialog = ({ closeDialog }) => {
    return (
        <BottomSheetDialog
            title="About"
            isOpen
            closeDialog={closeDialog}
        >
            <div className="d-flex flex-column p-3">
                <span className="fw-bold mb-1">Opening Hours</span>
                <RestaurantOperatingDayDisplay className="mt-1 mb-5" />

                <div className="d-flex align-items-center justify-content-center">
                    <Image
                        className="me-3"
                        imageSrc="/img/qr-ordering-logo-2.png"
                        width="130px"
                    />

                    <HintText>{`v${window.APP_SETTINGS_Version}`}</HintText>
                </div>
            </div>
        </BottomSheetDialog>
    );
}

export default AboutDialog;