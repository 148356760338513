import React from 'react';
import Image from 'components/Image';
import withSettings from 'hocs/withSettings';
import { createImagePlaceholder } from 'utils/imageUtils';

const MenuItemImage = ({ className, style, imageSrc, name, width, height, isLazyLoading = false, settings }) => {
    // Get image src
    let src = imageSrc;
    if (!src) src = settings.viewDefaultMenuItemImage ?? createImagePlaceholder(name);

    return (
        <Image
            className={className}
            style={style}
            imageSrc={src}
            width={width}
            height={height}
            isLazyLoading={isLazyLoading}
        />
    );
};

export default withSettings(MenuItemImage);