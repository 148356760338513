import React, { Component } from 'react';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as MessageBox from 'utils/messageBox';
import * as Session from 'utils/session';
import * as Toast from 'utils/toast';
import OrderDialog from '../components/OrderDialog';
import BillingDetailsDialog from '../components/BillingDetailsDialog';
import PaymentChannelDialog from '../components/PaymentChannelDialog';
import PaymentMethodDialog from '../components/PaymentMethodDialog';
import * as PaymentMethodConstants from '../data/paymentMethodConstant';
import * as PaymentGatewayHelper from '../utils/paymentGatewayHelper';

export default function withOrderFunction(WrappedComponent) {
    class WithOrder extends Component {
        constructor(props) {
            super(props);

            this.state = {
                formData: {
                    orderIdForPayment: null,
                    payLaterAtCounter: false,
                    paymentGatewayChannelId: null,
                    billingEmail: '',
                    billingName: '',
                    billingContact: '',
                    saveBillingDetails: false
                },
                showOrderDialog: false,
                showPaymentChannelDialog: false,
                showBillingDetailsDialog: false
            };

            this.orderDialog = React.createRef();

            this.tabKey = null;

            // - HOC functions
            this.viewCart = this.viewCart.bind(this);
            this.viewOrder = this.viewOrder.bind(this);

            // - Dialog functions
            this.openOrderDialog = this.openOrderDialog.bind(this);
            this.closeOrderDialog = this.closeOrderDialog.bind(this);
            this.openPaymentMethodDialog = this.openPaymentMethodDialog.bind(this);
            this.closePaymentMethodDialog = this.closePaymentMethodDialog.bind(this);
            this.openPaymentChannelDialog = this.openPaymentChannelDialog.bind(this);
            this.closePaymentChannelDialog = this.closePaymentChannelDialog.bind(this);
            this.openBillingDetailsDialog = this.openBillingDetailsDialog.bind(this);
            this.closeBillingDetailsDialog = this.closeBillingDetailsDialog.bind(this);

            // - Order entry functions
            this.confirmOrder = this.confirmOrder.bind(this);
            this.makePayment = this.makePayment.bind(this);
            this.setupInitialDataForEPayment = this.setupInitialDataForEPayment.bind(this);

            // - Order processing functions
            this.handleChange = this.handleChange.bind(this);
            this.resetFormData = this.resetFormData.bind(this);
            this.submitPaymentMethod = this.submitPaymentMethod.bind(this);
            this.submitPaymentChannel = this.submitPaymentChannel.bind(this);
            this.submitBillingDetails = this.submitBillingDetails.bind(this);

            // - Confirm order execution functions
            this.doConfirmOrder = this.doConfirmOrder.bind(this);
            this.handleConfirmOrderPaymentGatewayRedirectResult = this.handleConfirmOrderPaymentGatewayRedirectResult.bind(this);
            this.handleConfirmOrderSuccessResult = this.handleConfirmOrderSuccessResult.bind(this);

            // - Make payment execution functions
            this.doMakePayment = this.doMakePayment.bind(this);
            this.handleMakePaymentOngoingPaymentFoundResult = this.handleMakePaymentOngoingPaymentFoundResult.bind(this);
            this.handleMakePaymentPaymentGatewayRedirectResult = this.handleMakePaymentPaymentGatewayRedirectResult.bind(this);
            this.handleMakePaymentSuccessResult = this.handleMakePaymentSuccessResult.bind(this);

            // - Post execution functions
            this.onActionCompleted = this.onActionCompleted.bind(this);
            this.onActionError = this.onActionError.bind(this);

            // - Utility functions
            this.isConfirmOrderAction = this.isConfirmOrderAction.bind(this);
            this.isMakePaymentAction = this.isMakePaymentAction.bind(this);
            this.showPreviewModeWarning = this.showPreviewModeWarning.bind(this);
        }

        // HOC functions
        viewCart() {
            this.tabKey = 'order0';
            this.openOrderDialog();
        }

        viewOrder() {
            this.tabKey = 'order1';
            this.openOrderDialog();
        }

        // Dialog functions
        openOrderDialog() {
            this.setState({ showOrderDialog: true });
        }

        closeOrderDialog() {
            this.setState({ showOrderDialog: false });
        }

        openPaymentMethodDialog() {
            this.setState({ showPaymentMethodDialog: true });
        }

        closePaymentMethodDialog() {
            this.setState({ showPaymentMethodDialog: false });
        }

        openPaymentChannelDialog() {
            this.setState({ showPaymentChannelDialog: true });
        }

        closePaymentChannelDialog() {
            this.setState({ showPaymentChannelDialog: false });
        }

        openBillingDetailsDialog() {
            this.setState({ showBillingDetailsDialog: true });
        }

        closeBillingDetailsDialog() {
            this.setState({ showBillingDetailsDialog: false });
        }

        // Order entry functions
        async confirmOrder() {
            const { orderSessionData, settings } = this.props;
            const { isPreview, useEPayment } = orderSessionData;
            const { allowPayLaterAtCounter, restaurantUseEPayment } = settings;

            if (useEPayment && restaurantUseEPayment) {
                await this.setupInitialDataForEPayment();

                if (allowPayLaterAtCounter) {
                    this.openPaymentMethodDialog();
                } else {
                    this.openPaymentChannelDialog();
                }
            } else {
                if (isPreview) {
                    this.showPreviewModeWarning();
                    return;
                }

                const result = await MessageBox.showConfirmMessage('Are you sure to confirm order?');
                if (!result.confirm) return;

                await this.handleChange({ payLaterAtCounter: true });
                this.doConfirmOrder();
            }
        }

        async makePayment(e, orderId) {
            const { settings } = this.props;
            const { allowPayLaterAtCounter, restaurantUseEPayment } = settings;

            EventUtils.stopPropagation(e);

            if (restaurantUseEPayment) {
                await this.setupInitialDataForEPayment(orderId);

                if (allowPayLaterAtCounter) {
                    this.openPaymentMethodDialog();
                } else {
                    this.openPaymentChannelDialog();
                }
            } else {
                await this.handleChange({ orderIdForPayment: orderId, payLaterAtCounter: true });
                this.doMakePayment(true);
            }
        }

        async setupInitialDataForEPayment(orderId = null) {
            const billingDetails = Session.getBillingDetails();

            await this.handleChange({
                orderIdForPayment: orderId,
                payLaterAtCounter: false,
                paymentGatewayChannelId: null,
                billingEmail: billingDetails?.billingEmail ?? '',
                billingName: billingDetails?.billingName ?? '',
                billingContact: billingDetails?.billingContact ?? '',
                saveBillingDetails: !!billingDetails
            });
        }

        // Order processing functions
        handleChange(values) {
            // Return a promise to allow the caller to wait for the form data to be updated
            return new Promise(resolve => {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        ...values
                    }
                }, resolve);
            });
        }

        resetFormData() {
            this.handleChange({
                orderIdForPayment: null,
                payLaterAtCounter: false,
                paymentGatewayChannelId: null,
                billingEmail: '',
                billingName: '',
                billingContact: '',
                saveBillingDetails: false
            });
        }

        async submitPaymentMethod(paymentMethod) {
            const { orderSessionData } = this.props;
            const { isPreview } = orderSessionData;

            if (paymentMethod === PaymentMethodConstants.EPayment) {
                this.openPaymentChannelDialog();
            } else if (paymentMethod === PaymentMethodConstants.PayLaterAtCounter) {
                if (isPreview && this.isConfirmOrderAction()) {
                    this.showPreviewModeWarning();
                    return;
                }

                await this.handleChange({
                    payLaterAtCounter: true,
                    paymentGatewayChannelId: null,
                    billingEmail: '',
                    billingName: '',
                    billingContact: '',
                    saveBillingDetails: false
                });

                if (this.isConfirmOrderAction()) {
                    this.doConfirmOrder();
                } else if (this.isMakePaymentAction()) {
                    this.doMakePayment(true);
                }
            }
        }

        submitPaymentChannel() {
            this.openBillingDetailsDialog();
        }

        submitBillingDetails() {
            const { orderSessionData } = this.props;
            const { isPreview } = orderSessionData;

            if (isPreview) {
                this.showPreviewModeWarning();
                return;
            }

            if (this.isConfirmOrderAction()) {
                this.doConfirmOrder();
            } else if (this.isMakePaymentAction()) {
                this.doMakePayment(true);
            }
        }

        // - Confirm order execution functions
        async doConfirmOrder() {
            const { orderService } = this.props;
            const { formData } = this.state;
            const { payLaterAtCounter, paymentGatewayChannelId, billingEmail, billingName, billingContact } = formData;

            this.props.blockUI();

            try {
                const result = await orderService.confirmOrder(payLaterAtCounter, paymentGatewayChannelId, billingEmail, billingName, billingContact);

                this.props.unblockUI();
                this.onActionCompleted();
                this.orderDialog.current.tabRef.current.show('order1');

                if (result.requestDataInJson && result.requestUrl) {
                    this.handleConfirmOrderPaymentGatewayRedirectResult(result);
                } else {
                    this.handleConfirmOrderSuccessResult();
                }
            } catch (errorMessage) {
                this.props.unblockUI();
                this.onActionError(errorMessage);
            }
        }

        handleConfirmOrderPaymentGatewayRedirectResult(result) {
            PaymentGatewayHelper.submitPaymentGatewayForm(result, this.props.blockUI, true);
        }

        handleConfirmOrderSuccessResult() {
            Toast.showSuccessMessage('Order has been confirmed successfully.');
        }

        // - Make payment execution functions
        async doMakePayment(validateHasAnyOngoingPayment) {
            const { orderService } = this.props;
            const { formData } = this.state;
            const { orderIdForPayment, payLaterAtCounter, paymentGatewayChannelId, billingEmail, billingName, billingContact } = formData;

            this.props.blockUI();

            try {
                const result = await orderService.makePayment(
                    orderIdForPayment,
                    validateHasAnyOngoingPayment,
                    payLaterAtCounter,
                    paymentGatewayChannelId,
                    billingEmail,
                    billingName,
                    billingContact);

                this.props.unblockUI();

                if (result.isOngoingPaymentFound) {
                    await this.handleMakePaymentOngoingPaymentFoundResult(payLaterAtCounter);
                } else if (result.requestDataInJson && result.requestUrl) {
                    this.handleMakePaymentPaymentGatewayRedirectResult(result);
                } else {
                    this.handleMakePaymentSuccessResult();
                }
            } catch (errorMessage) {
                this.props.unblockUI();
                this.onActionError(errorMessage);
            }
        }

        async handleMakePaymentOngoingPaymentFoundResult(payLaterAtCounter) {
            if (payLaterAtCounter) {
                const message = 'You are not allowed to select "Pay Later At Counter" right now because ongoing e-payments were found for this order. Please try again later.';
                MessageBox.showMessage(message);
                this.onActionCompleted(false);
            } else {
                const message = 'Ongoing e-payments were found for this order. Please check to avoid duplicate payments.';
                const messageBoxResult = await MessageBox.showConfirmMessage(message, '', false, 'Proceed', 'Cancel');

                if (messageBoxResult.confirm) {
                    this.doMakePayment(false);
                } else {
                    this.onActionCompleted(false);
                }
            }
        }

        handleMakePaymentPaymentGatewayRedirectResult(result) {
            this.onActionCompleted();
            PaymentGatewayHelper.submitPaymentGatewayForm(result, this.props.blockUI, false);
        }

        handleMakePaymentSuccessResult() {
            this.orderDialog.current.syncOrders(false);
            this.onActionCompleted();
            Toast.showSuccessMessage('Order has been sent to counter successfully.');
        }

        // Post execution functions
        onActionCompleted(updateBillingDetails = true) {
            const { formData, showPaymentMethodDialog, showPaymentChannelDialog, showBillingDetailsDialog } = this.state;
            const { paymentGatewayChannelId, billingEmail, billingName, billingContact, saveBillingDetails } = formData;

            if (updateBillingDetails && paymentGatewayChannelId !== null) {
                // E-Payment option is selected, update billing details in local storage
                if (saveBillingDetails) {
                    Session.setBillingDetails(billingEmail, billingName, billingContact);
                } else {
                    Session.removeBillingDetails();
                }
            }

            if (showPaymentMethodDialog) this.closePaymentMethodDialog();
            if (showPaymentChannelDialog) this.closePaymentChannelDialog();
            if (showBillingDetailsDialog) this.closeBillingDetailsDialog();

            this.resetFormData();
        }

        onActionError(errorMessage) {
            const { showPaymentMethodDialog, showPaymentChannelDialog, showBillingDetailsDialog } = this.state;

            Toast.showErrorMessage(errorMessage);

            if (showPaymentMethodDialog) this.closePaymentMethodDialog();
            if (showPaymentChannelDialog) this.closePaymentChannelDialog();
            if (showBillingDetailsDialog) this.closeBillingDetailsDialog();

            this.resetFormData();
        }

        // Utility functions
        isConfirmOrderAction() {
            const { formData } = this.state;
            const { orderIdForPayment } = formData;

            return orderIdForPayment === null;
        }

        isMakePaymentAction() {
            const { formData } = this.state;
            const { orderIdForPayment } = formData;

            return orderIdForPayment !== null;
        }

        showPreviewModeWarning() {
            MessageBox.showMessage('You are not allowed to confirm order in preview mode.');
        }

        render() {
            const { formData, showOrderDialog, showPaymentMethodDialog, showPaymentChannelDialog, showBillingDetailsDialog } = this.state;
            const { paymentGatewayChannelId, billingEmail, billingName, billingContact, saveBillingDetails } = formData;

            return (
                <>
                    <WrappedComponent
                        viewCart={this.viewCart}
                        viewOrder={this.viewOrder}
                        {...this.props}
                    />

                    {showOrderDialog &&
                        <OrderDialog
                            tabKey={this.tabKey}
                            closeOrderDialog={this.closeOrderDialog}
                            confirmOrder={this.confirmOrder}
                            makePayment={this.makePayment}
                            ref={this.orderDialog}
                        />
                    }

                    {showPaymentMethodDialog &&
                        <PaymentMethodDialog
                            closeDialog={this.closePaymentMethodDialog}
                            handleSubmit={this.submitPaymentMethod}
                        />
                    }

                    {showPaymentChannelDialog &&
                        <PaymentChannelDialog
                            paymentGatewayChannelId={paymentGatewayChannelId}
                            closeDialog={this.closePaymentChannelDialog}
                            handleChange={this.handleChange}
                            handleSubmit={this.submitPaymentChannel}
                        />
                    }

                    {showBillingDetailsDialog &&
                        <BillingDetailsDialog
                            billingEmail={billingEmail}
                            billingName={billingName}
                            billingContact={billingContact}
                            saveBillingDetails={saveBillingDetails}
                            closeDialog={this.closeBillingDetailsDialog}
                            handleChange={this.handleChange}
                            handleSubmit={this.submitBillingDetails}
                        />
                    }
                </>
            );
        }
    }

    WithOrder.displayName = `WithOrder(${ComponentUtils.getDisplayName(WrappedComponent)})`;

    const hocs = [
        withBlockUI,
        withOrderService
    ];

    return ComponentUtils.compose(hocs)(WithOrder);
}