import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import HintText from 'components/HintText';
import Image from 'components/Image';
import { cartModes, getCartMode } from '../data/cartMode';
import * as CartModeConstants from '../data/cartModeConstant';

const CartModeDialog = ({ cartMode, isUpdate, joinedUserCount, closeDialog, onCartModeSelected }) => {
    const title = isUpdate ? 'Select Cart Mode' : 'Your Cart Mode';

    function renderCartModePanel(cartModeItem) {
        const isShareCart = cartModeItem.value === CartModeConstants.ShareCart;
        let className = "apps-ui-cart-mode-component-panel position-relative mb-3";
        if (isUpdate) className += ' can-toggle-background';
        if (isShareCart && isUpdate) className += ' show-suggest-label';

        return (
            <div
                key={cartModeItem.value}
                className={className}
                onClick={() => onCartModeSelected(cartModeItem.value)}
            >
                <div className="container d-flex position-relative bg-white w-100 h-100 border border-1 p-4" style={{ borderRadius: '6px' }}>
                    <Image imageSrc={cartModeItem.imageSrc} />

                    <div className="flex-grow-1 d-flex flex-column ps-3">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold">{cartModeItem.title}</span>

                            {isShareCart &&
                                <div
                                    className="d-flex align-items-center text-white ms-2 px-2"
                                    style={{
                                        background: '#777777',
                                        borderRadius: '20px',
                                        paddingTop: '0.1rem',
                                        paddingBottom: '0.1rem'
                                    }}
                                >
                                    <span className="icon-icon-guest2guest1 fs-6 me-1"></span>
                                    <span className="fs-5">{joinedUserCount}</span>
                                </div>
                            }
                        </div>

                        <HintText>{cartModeItem.description}</HintText>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BottomSheetDialog
            title={title}
            isOpen
            closeDialog={closeDialog}
        >
            <div className="d-flex flex-column m-3">
                {isUpdate
                    ? cartModes.map(x => renderCartModePanel(x))
                    : renderCartModePanel(getCartMode(cartMode))
                }
            </div>
        </BottomSheetDialog>
    )
};

export default CartModeDialog;