import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import EmptyLine from 'components/EmptyLine';
import Image from 'components/Image';
import Radio from 'components/Radio';
import RippleWrapper from 'components/RippleWrapper';
import { getPaymentChannel, getPaymentChannelText } from 'data/paymentChannelText';
import withSettings from 'hocs/withSettings';
import * as ComponentUtils from 'utils/component';

const PaymentChannelDialog = ({ paymentGatewayChannelId, closeDialog, handleChange, handleSubmit, settings }) => {
    const { restaurantPaymentChannels } = settings;

    const isPaymentChannelSelected = paymentGatewayChannelId !== null;
    const items = buildPaymentChannelItems();

    function buildPaymentChannelItems() {
        const results = restaurantPaymentChannels
            .reduce((arr, x) => {
                const paymentChannelItem = getPaymentChannel(x.paymentChannelId);

                if (paymentChannelItem !== null) {
                    arr.push({
                        ...x,
                        text: paymentChannelItem.text,
                        imageSrc: paymentChannelItem.imageSrc
                    });
                }

                return arr;
            }, [])
            .sort((a, b) => {
                return a.paymentChannelId - b.paymentChannelId;
            });

        return results;
    }

    function renderPaymentChannel(item, isLastPaymentChannel) {
        const value = `payment-channel-${item.paymentChannelId}`;

        return (
            <React.Fragment key={value}>
                <RippleWrapper className="d-flex align-items-center p-3" htmlFor={value}>
                    <Image
                        className="border p-1"
                        style={{ objectFit: 'contain', borderRadius: '6px' }}
                        width="65px"
                        height="65px"
                        imageSrc={item.imageSrc}
                    />

                    <span className="flex-grow-1 fs-5 ms-3" style={{ fontWeight: 600 }}>
                        {item.text}
                    </span>

                    <span className="col-1 d-flex align-items-center align-self-center">
                        <Radio
                            value={value}
                            groupValue="payment-channel-list"
                            checked={paymentGatewayChannelId === item.paymentGatewayChannelId}
                            handleChange={() => handleChange({ paymentGatewayChannelId: item.paymentGatewayChannelId })}
                        />
                    </span>
                </RippleWrapper>

                {!isLastPaymentChannel &&
                    <EmptyLine />
                }
            </React.Fragment>
        );
    }

    return (
        <BottomSheetDialog
            title="Select Payment Channel"
            isOpen
            closeDialog={closeDialog}
            footer={
                <div
                    className="apps-general-box-shadow d-flex justify-content-center py-3"
                    style={{ borderTop: '1px solid var(--grey-300)' }}
                >
                    <Button
                        className="col-8 py-2"
                        primary
                        disabled={!isPaymentChannelSelected}
                        onClick={handleSubmit}
                    >
                        {isPaymentChannelSelected
                            ? `Pay Using ${getPaymentChannelText(restaurantPaymentChannels.find(x => x.paymentGatewayChannelId === paymentGatewayChannelId).paymentChannelId)}`
                            : 'No Payment Channel Selected'
                        }
                    </Button>
                </div>
            }
        >
            <div className="d-flex flex-column">
                {items.map((x, index) => renderPaymentChannel(x, index === items.length - 1))}
            </div>
        </BottomSheetDialog>
    );
}

const hocs = [
    withSettings
];

export default React.memo(ComponentUtils.compose(hocs)(PaymentChannelDialog));