import { Component } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import BlockUI from 'components/BlockUI';
import * as DocumentIdConstants from 'data/documentIdConstant';
import WithRouter from 'hocs/withRouter';
import Router from 'layouts/Router';
import BlockUIContext from 'ui/context/BlockUIContext';
import MenuContext from 'ui/context/MenuContext';
import OrderContext from 'ui/context/OrderContext';
import SettingsContext from 'ui/context/SettingsContext';
import UserContext from 'ui/context/UserContext';
import UserDeviceSettingsContext from 'ui/context/UserDeviceSettingsContext';
import Cart from 'ui/objects/Cart';
import Menu from 'ui/objects/Menu';
import User from 'ui/objects/User';
import * as ApplicationApi from 'ui/services/applicationApi';
import * as OrderApi from 'ui/services/orderApi';
import * as BackgroundTask from 'utils/backgroundTask';
import * as ColorUtils from 'utils/colorUtils';
import * as ErrorMessage from 'utils/errorMessage';
import { pagePaths } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as Session from 'utils/session';
import SettingValues from 'utils/SettingValues';
import * as Toast from 'utils/toast';

class App extends Component {
    constructor(props) {
        super(props);

        this.fetchChangeLogsTimer = null;
        this.lastCartItemChangeLogId = -1;
        this.lastUserSessionChangeLogId = -1;

        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
        this.onNetworkOnlineDetected = this.onNetworkOnlineDetected.bind(this);
        this.onNetworkOfflineDetected = this.onNetworkOfflineDetected.bind(this);

        this.blockUI = this.blockUI.bind(this);
        this.unblockUI = this.unblockUI.bind(this);
        this.fetchSettings = this.fetchSettings.bind(this);
        this.startOrder = this.startOrder.bind(this);
        this.onCartUpdated = this.onCartUpdated.bind(this);
        this.onUserUpdated = this.onUserUpdated.bind(this);

        this.state = {
            settingsContextValue: {
                settings: new SettingValues({})
            },
            userDeviceSettingsContextValue: {
                isPageVisible: true,
                isNetworkOnline: true
            },
            blockUIContextValue: {
                blocking: false,
                message: null,
                blockUI: this.blockUI,
                unblockUI: this.unblockUI
            },
            menuContextValue: {
                menu: new Menu()
            },
            userContextValue: {
                user: new User(null, this.onUserUpdated)
            },
            orderContextValue: {
                orderSessionData: {
                    qrCode: Session.getQRCode(),
                    tableNo: Session.getTableNo(),
                    isQRCodeStatic: Session.getIsQRCodeStatic(),
                    isPreview: Session.getIsPreview(),
                    orderServiceType: Session.getServiceType(),
                    shareCart: Session.getShareCart(),
                    useEPayment: Session.getUseEPayment(),
                },
                cart: new Cart(null, this.onCartUpdated, null, Session.getServiceType()),
                startOrder: this.startOrder
            },
            isFetchingSettings: true,
            isFailedFetchingSettings: false
        };
    }

    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilityChanged);
        window.addEventListener('online', this.onNetworkOnlineDetected);
        window.addEventListener('offline', this.onNetworkOfflineDetected);

        BackgroundTask.startCheckVersion();
        this.fetchSettings();
    }

    componentDidUpdate() {
        if (this.props.location.pathname === pagePaths.Home && this.fetchChangeLogsTimer) {
            // Stop fetching ChangeLogs if redirect to Home page
            clearTimeout(this.fetchChangeLogsTimer);
        }
    }

    componentWillUnmount() {
        if (this.fetchChangeLogsTimer) {
            clearTimeout(this.fetchChangeLogsTimer);
        }

        document.removeEventListener('visibilitychange', this.onVisibilityChanged);
    }

    onVisibilityChanged() {
        // CX-TODO : Consider to help user to leave the order immediately if stay inactive for more than probably 1 minute,
        // note that change log fetching will be stopping automatically once redirect to home page 
        // this is already done in componentDidUpdate
        this.setState({
            userDeviceSettingsContextValue: {
                ...this.state.userDeviceSettingsContextValue,
                // Refer to https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
                isPageVisible: document.visibilityState !== 'hidden'
            }
        });
    }

    onNetworkOnlineDetected() {
        Toast.showSuccessMessage('Your device is back online.');

        this.setState({
            userDeviceSettingsContextValue: {
                ...this.state.userDeviceSettingsContextValue,
                // Refer to https://developer.mozilla.org/en-US/docs/Web/API/Window/online_event
                isNetworkOnline: true
            }
        });
    }

    onNetworkOfflineDetected() {
        Toast.showWarningMessage('Your device is now offline.');

        this.setState({
            userDeviceSettingsContextValue: {
                ...this.state.userDeviceSettingsContextValue,
                // Refer to https://developer.mozilla.org/en-US/docs/Web/API/Window/offline_event
                isNetworkOnline: false
            }
        });
    }

    blockUI(message = null) {
        this.setState({
            blockUIContextValue: {
                ...this.state.blockUIContextValue,
                blocking: true,
                message
            }
        });
    }

    unblockUI() {
        this.setState({
            blockUIContextValue: {
                ...this.state.blockUIContextValue,
                blocking: false,
                message: null
            }
        });
    }

    updateThemeColor(themeColor) {
        document.documentElement.style.setProperty('--primary', themeColor);
        document.documentElement.style.setProperty('--primary-dark', ColorUtils.generateColor(themeColor, false, 20));
        document.documentElement.style.setProperty('--primary-light', ColorUtils.generateColor(themeColor, true, 80));
    }

    fetchSettings() {
        const { orderSessionData } = this.state.orderContextValue;
        const { qrCode } = orderSessionData;

        ApplicationApi.getSettings(qrCode)
            .then(settings => {
                if (settings.viewThemeColorCode) {
                    this.updateThemeColor(settings.viewThemeColorCode);
                }

                this.setState({
                    settingsContextValue: {
                        ...this.state.settingsContextValue,
                        settings: new SettingValues(settings)
                    },
                    isFetchingSettings: false
                });
            })
            .catch(error => {
                Toast.showErrorMessage(ErrorMessage.fetchError(error));

                this.setState({
                    isFetchingSettings: false,
                    isFailedFetchingSettings: true
                });
            });
    }

    startOrder(result, routeOptions) {
        const { settings } = this.state.settingsContextValue;
        const { orderSessionData } = this.state.orderContextValue;
        const { orderServiceType } = orderSessionData;

        const { tableSessionId, shareCart, useEPayment, menu, cart } = result;

        Session.setTableSessionId(tableSessionId);
        Session.setShareCart(shareCart);
        Session.setUseEPayment(useEPayment);

        BackgroundTask.startCheckStorageData();

        this.setState({
            menuContextValue: {
                ...this.state.menuContextValue,
                menu: new Menu(menu)
            },
            userContextValue: {
                ...this.state.userContextValue,
                user: new User(Session.getUserId(), this.onUserUpdated)
            },
            orderContextValue: {
                ...this.state.orderContextValue,
                orderSessionData: {
                    ...this.state.orderContextValue.orderSessionData,
                    shareCart,
                    useEPayment
                },
                cart: new Cart(cart, this.onCartUpdated, settings, orderServiceType)
            }
        }, async () => {
            if (shareCart) {
                this.lastCartItemChangeLogId = -1;
                this.lastUserSessionChangeLogId = -1;
                await this.fetchChangeLogs(true);
            }

            RoutesUtils.navigateToPage(this.props.history, 'Menu', routeOptions);
        });
    }

    onCartUpdated() {
        this.setState({ orderContextValue: { ...this.state.orderContextValue } });
    }

    onUserUpdated() {
        this.setState({ userContextValue: { ...this.state.userContextValue } });
    }

    async fetchChangeLogs(isInitialLoad) {
        const { isPageVisible, isNetworkOnline } = this.state.userDeviceSettingsContextValue;
        const { cart } = this.state.orderContextValue;
        const { user } = this.state.userContextValue;

        const fetchChangeLogsIntervalInMilliseconds = window.APP_SETTINGS_FetchChangeLogsIntervalInSeconds * 1000;
        const fetchNextChangeLogs = () => this.fetchChangeLogsTimer = setTimeout(() => this.fetchChangeLogs(false), fetchChangeLogsIntervalInMilliseconds);

        try {
            const messageBoxEl = document.getElementById(DocumentIdConstants.GeneralReloadMessageBox);

            if (messageBoxEl === null && isPageVisible && isNetworkOnline) {
                const result = await OrderApi.getChangeLogs(this.lastCartItemChangeLogId, this.lastUserSessionChangeLogId);
                const { cartItemChangeLogs, userSessionChangeLogs } = result;

                if (cartItemChangeLogs.length !== 0) {
                    this.lastCartItemChangeLogId = Math.max(...cartItemChangeLogs.map(x => x.cartItemChangeLogId));
                    cart.applyChangeLogs(cartItemChangeLogs);
                }

                if (userSessionChangeLogs.length !== 0) {
                    this.lastUserSessionChangeLogId = Math.max(...userSessionChangeLogs.map(x => x.userSessionChangeLogId));
                    user.applyChangeLogs(userSessionChangeLogs, isInitialLoad);
                }
            }
        } catch (error) {
            // Ignore error
        } finally {
            fetchNextChangeLogs();
        }
    }

    render() {
        const { isFetchingSettings, isFailedFetchingSettings } = this.state;
        if (isFailedFetchingSettings) return;
        
        return (
            isFetchingSettings
                ? <BlockUI blocking />
                : <BlockUIContext.Provider value={this.state.blockUIContextValue}>
                    <MenuContext.Provider value={this.state.menuContextValue}>
                        <OrderContext.Provider value={this.state.orderContextValue}>
                            <SettingsContext.Provider value={this.state.settingsContextValue}>
                                <UserContext.Provider value={this.state.userContextValue}>
                                    <UserDeviceSettingsContext.Provider value={this.state.userDeviceSettingsContextValue}>
                                        <BlockUI
                                            blocking={this.state.blockUIContextValue.blocking}
                                            message={this.state.blockUIContextValue.message}
                                        />
                                        <ToastContainer
                                            bodyClassName="react-toastify-toast-body"
                                            position="top-center"
                                            autoClose={4000}
                                            closeOnClick
                                            pauseOnHover={false}
                                            pauseOnFocusLoss={false}
                                            draggable={false}
                                            theme="colored"
                                            transition={Slide}
                                        />
                                        <Router />
                                    </UserDeviceSettingsContext.Provider>
                                </UserContext.Provider>
                            </SettingsContext.Provider>
                        </OrderContext.Provider>
                    </MenuContext.Provider>
                </BlockUIContext.Provider>
        );
    }
}

export default WithRouter(App);