import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import BlockUI from 'components/BlockUI';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import * as HomePageErrorCodes from 'pages/Home/data/errorCode';
import * as ApplicationApi from 'ui/services/applicationApi';
import * as Session from 'utils/session';
import { pagePaths } from 'utils/routes';
import * as RoutesUtils from 'utils/routesUtils';
import * as ThemeUtils from 'utils/themeUtils';
import App from 'App';

ThemeUtils.updateTheme();

const rootElement = ReactDOM.createRoot(document.getElementById('root'));
const blockUiElement = ReactDOM.createRoot(document.getElementById('blockui'));
const showLoading = () => blockUiElement.render(<BlockUI blocking />);
const hideLoading = () => blockUiElement.unmount();

const pathName = window.location.pathname;
const urlParams = new URLSearchParams(window.location.search);
const urlAccountBookId = urlParams.get('accountBookId');
const urlQRCode = urlParams.get('qrCode');
const urlErrorCode = urlParams.get('errorCode');

if (urlAccountBookId && urlQRCode) {
    handleScanQRCode();
} else {
    handle();
}

function handleScanQRCode() {
    showLoading();

    // Remove data from session storage
    Session.removeSessionData();

    Session.setAccountBookId(urlAccountBookId);

    ApplicationApi.validateQRCode(urlQRCode)
        .then(result => {
            hideLoading();
            
            if (result.isQRCodeInvalid) {
                RoutesUtils.redirectToPage(pagePaths.QRCodeInvalid);
            } else {
                Session.setQRCode(result.qrCodeId);
                Session.setTableNo(result.tableNo);
                Session.setServiceType(result.serviceType);
                Session.setIsQRCodeStatic(result.isQRCodeStatic);
                Session.setIsPreview(result.isPreview);

                if (result.isQRCodeExpired) {
                    RoutesUtils.redirectToHomePageWithError({ errorCode: HomePageErrorCodes.QRCodeExpired });
                } else {
                    RoutesUtils.redirectToPage(pagePaths.Home);
                }
            }
        })
        .catch(() => {
            hideLoading();
            RoutesUtils.redirectToPage(pagePaths.QRCodeInvalid);
        });
}

async function handle() {
    const accountBookId = parseIntValue(Session.getAccountBookId());
    const tableSessionId = parseIntValue(Session.getTableSessionId());
    const serviceType = Session.getServiceType();
    const isPreview = Session.getIsPreview();

    let qrCode = Session.getQRCode();
    let tableNo = Session.getTableNo();

    if (tableSessionId && qrCode && tableNo) {
        // Check has change table to update QRCode & TableNo
        showLoading();

        try {
            const result = await ApplicationApi.hasChangeTable(qrCode, tableSessionId);
            hideLoading();

            if (result.hasChangeTable) {
                Session.setQRCode(result.qrCodeId);
                Session.setTableNo(result.tableNo);
                qrCode = result.qrCodeId;
                tableNo = result.tableNo;
            }
        } catch {
            hideLoading();
            RoutesUtils.redirectToPage(pagePaths.QRCodeInvalid);
            return;
        }
    }

    const isQRCodeScannedValid =
        accountBookId &&
        qrCode &&
        ((serviceType === ServiceTypeConstants.DineIn && tableNo) || serviceType === ServiceTypeConstants.TakeAway || isPreview);

    if (!isQRCodeScannedValid && urlErrorCode === null) {
        RoutesUtils.redirectToPage(pagePaths.QRCodeInvalid);
        return;
    }

    if (!pathName.startsWith('/home') && !pathName.startsWith('/redirect')) {
        // Redirect to Home Page by default
        RoutesUtils.redirectToPage(pagePaths.Home);
    }

    rootElement.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}

function parseIntValue(value) {
    const intValue = parseInt(value);
    return isNaN(intValue) ? null : intValue;
}