import React from 'react';
import * as DocumentIdConstants from 'data/documentIdConstant';

const BackButton = ({ children, ...rest }) => (
    <span
        id={DocumentIdConstants.ComponentBackButton}
        {...rest}
    >
        {children}
    </span>
);

export default BackButton;