import React from 'react';
import BottomSheetDialog from 'components/BottomSheetDialog';
import Button from 'components/Button';
import HintText from 'components/HintText';
import Image from 'components/Image';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import withRouter from 'hocs/withRouter';
import withUser from 'hocs/withUser';
import * as ComponentUtils from 'utils/component';
import * as RoutesUtils from 'utils/routesUtils';
import * as Toast from 'utils/toast';

const QuitOrderDialog = ({ history, user, orderService, orderSessionData, blockUI, unblockUI, closeDialog }) => {
    const { shareCart } = orderSessionData;

    function quitOrder() {
        blockUI();

        orderService.leaveUserSession()
            .then(() => {
                unblockUI();
                closeDialog();
                RoutesUtils.navigateToPage(history, 'Home');
            })
            .catch(errorMessage => {
                unblockUI();
                closeDialog();
                Toast.showErrorMessage(errorMessage);
            });
    }

    return (
        <BottomSheetDialog isOpen closeDialog={closeDialog}>
            <div className="d-flex flex-column align-items-center my-3">
                <Image imageSrc="/img/quit-order-logo.png" width="90px" />

                <span className="fs-1 fw-bold mt-2">
                    Quit Order?
                </span>

                <div className="d-flex flex-column text-center w-75 mt-2">
                    <span className="mb-3"> No problem! You can always return by scanning the QR code.</span>

                    {shareCart && user.getJoinedUserItemCount() === 1 &&
                        <HintText className="mb-3">
                            {`Just a heads-up: If you leave, all items in your cart will be removed since you're the only one here.`}
                        </HintText>
                    }
                </div>

                <Button
                    className="mb-3"
                    style={{ width: '85%' }}
                    primary
                    onClick={quitOrder}
                >
                    Quit Order
                </Button>

                <Button
                    style={{ width: '85%' }}
                    light
                    onClick={closeDialog}
                >
                    Cancel
                </Button>
            </div>
        </BottomSheetDialog>
    );
};

const hocs = [
    withBlockUI,
    withOrderService,
    withRouter,
    withUser
];

export default ComponentUtils.compose(hocs)(QuitOrderDialog);