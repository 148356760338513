import React from 'react';
import Icon from 'components/Icon';
import * as ServiceTypeConstants from 'data/serviceTypeConstant';
import withBlockUI from 'hocs/withBlockUI';
import withOrderService from 'hocs/withOrderService';
import MenuItemImage from 'Menu/components/MenuItemImage';
import MenuItemName from 'Menu/components/MenuItemName';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import MenuItemQuantityPanel from 'Menu/components/MenuItemQuantityPanel';
import { withMenuItemFunction } from 'ui/MenuItem';
import * as ComponentUtils from 'utils/component';
import * as EventUtils from 'utils/eventUtils';
import * as ObjectUtils from 'utils/objectUtils';
import * as Toast from 'utils/toast';
import * as MessageBox from 'utils/messageBox';
import ItemDetailUIBuilder from '../../utils/itemDetailUIBuilder';

const CartItemPanel = ({ orderService, orderSessionData, blockUI, unblockUI, cartItem, updateItem }) => {
    const { orderServiceType } = orderSessionData;

    const itemDetailUIBuilder = ItemDetailUIBuilder.createFromCart(cartItem, orderServiceType);

    function onServiceTypeChanged(e) {
        EventUtils.stopPropagation(e);

        const cartItemCloned = ObjectUtils.deepCopy(cartItem);
        cartItemCloned.serviceType = cartItemCloned.serviceType === ServiceTypeConstants.DineIn
            ? ServiceTypeConstants.TakeAway
            : ServiceTypeConstants.DineIn;

        blockUI();

        orderService.updateCartItem(cartItemCloned)
            .then(() => {
                unblockUI();
            })
            .catch(errorMessage => {
                unblockUI();
                Toast.showErrorMessage(errorMessage);
            });
    }

    function onQuantityChanged(e, quantity, isAdd) {
        EventUtils.stopPropagation(e);

        if (quantity === 0) {
            onRemoved();
            return;
        }

        blockUI();

        orderService.updateCartItemQuantity(cartItem.cartItemId, isAdd)
            .then(() => {
                unblockUI();
            })
            .catch(errorMessage => {
                unblockUI();
                Toast.showErrorMessage(errorMessage);
            });
    }

    function onRemoved(e) {
        EventUtils.stopPropagation(e);

        MessageBox.showConfirmMessage('Are you sure to delete this item?')
            .then(result => {
                if (result.confirm) {
                    blockUI();

                    orderService.removeCartItem(cartItem.cartItemId)
                        .then(e => {
                            unblockUI();
                            Toast.showSuccessMessage('Item deleted successfully.');
                        })
                        .catch(errorMessage => {
                            unblockUI();
                            Toast.showErrorMessage(errorMessage);
                        });
                }
            });
    }

    function onPanelClicked() {
        updateItem(cartItem.cartItemId)
            .then(e => {
                if (e.actionMessage) Toast.showSuccessMessage(e.actionMessage);
            })
            .catch(errorMessage => {
                Toast.showErrorMessage(errorMessage);
            });
    }

    return (
        <div
            className="apps-general-shimmer d-flex flex-column bg-white pt-3"
            onClick={onPanelClicked}
        >
            {/* Detail Content */}
            <div className="d-flex mb-3">
                <MenuItemImage
                    className="border"
                    style={{ borderRadius: '6px' }}
                    imageSrc={cartItem.image}
                    name={cartItem.name}
                    width='80px'
                    height='80px'
                    isLazyLoading
                />

                <div className="flex-grow-1 d-flex flex-column ps-3">
                    <div className="d-flex fs-5">
                        <span className="flex-grow-1 fw-bold">
                            <MenuItemName name={cartItem.name} displayCode={cartItem.displayCode} />
                        </span>

                        <MenuItemPrice
                            as="span"
                            className="ps-1"
                            value={cartItem.subTotal}
                            isCurrencyFormat
                        />
                    </div>

                    {itemDetailUIBuilder.build()}
                </div>
            </div>

            {/* Action Content */}
            <div className="d-flex align-items-center" style={{ height: '35px' }}>
                <div
                    className="d-flex align-items-center icon-icon-trash rounded-circle"
                    style={{
                        padding: '10px',
                        border: '1px solid var(--grey-400)',
                        height: 'inherit'
                    }}
                    onClick={onRemoved}
                />

                {orderServiceType === ServiceTypeConstants.DineIn &&
                    <Icon
                        className="d-flex align-items-center ms-3"
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            height: 'inherit',
                            border: cartItem.serviceType === ServiceTypeConstants.TakeAway ? undefined : '1px solid var(--grey-400)',
                            color: cartItem.serviceType === ServiceTypeConstants.TakeAway ? 'white' : undefined,
                            background: cartItem.serviceType === ServiceTypeConstants.TakeAway ? 'var(--primary)' : undefined
                        }}
                        name="icon-icon-bag"
                        label="Take Away"
                        showIconFirst
                        onClick={onServiceTypeChanged}
                    />
                }

                <MenuItemQuantityPanel
                    className="ms-auto"
                    height="inherit"
                    value={cartItem.quantity}
                    handleAdd={e => onQuantityChanged(e, cartItem.quantity + 1, true)}
                    handleMinus={e => onQuantityChanged(e, cartItem.quantity - 1, false)}
                />
            </div>
        </div >
    );
}

const hocs = [
    withMenuItemFunction,
    withOrderService,
    withBlockUI
];

export default ComponentUtils.compose(hocs)(CartItemPanel);