import CryptoJS from 'crypto-js';
import * as GuidUtils from 'utils/guidUtils';

// API
export const buildApiUrl = (path, parameters) => {
    const accountBookId = getAccountBookId() || 0;
    let url = `/api/${accountBookId}/${path}`;

    if (parameters) {
        let queryString = '';

        for (const prop in parameters) {
            const propValue = parameters[prop];

            if (propValue !== null && propValue !== undefined) {
                if (Array.isArray(propValue)) {
                    for (let i = 0; i < propValue.length; i++) {
                        queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue[i])}`;
                    }
                } else {
                    queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue)}`;
                }
            }
        }

        url += queryString;
    }

    return url;
};

// Local storage
export const getUserId = () => localStorage.getItem('userId');
export const setUserId = () => localStorage.setItem('userId', GuidUtils.generate());

export const getBillingDetails = () => {
    const encryptedBillingDetails = localStorage.getItem('billingDetails');
    if (encryptedBillingDetails === null) return null;

    try {
        const decryptedBillingDetails = decrypt(encryptedBillingDetails);
        const billingDetails = JSON.parse(decryptedBillingDetails);

        return billingDetails;
    } catch {
        // Return null if decryption or parsing of billing details fails
        return null;
    }
};

export const setBillingDetails = (billingEmail, billingName, billingContact) => {
    const billingDetails = {};

    if (billingEmail) billingDetails.billingEmail = billingEmail;
    if (billingName) billingDetails.billingName = billingName;
    if (billingContact) billingDetails.billingContact = billingContact;

    const billingDetailsInJson = JSON.stringify(billingDetails);
    const encryptedBillingDetails = encrypt(billingDetailsInJson);

    localStorage.setItem('billingDetails', encryptedBillingDetails);
};

export const removeBillingDetails = () => localStorage.removeItem('billingDetails');

const getSecretKey = () => {
    return window.APP_SETTINGS_EncryptionSecretKey;
};

const encrypt = (data) => {
    const secretKey = getSecretKey();

    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

const decrypt = (data) => {
    const secretKey = getSecretKey();

    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
};

// Session storage
export const getAccountBookId = () => getSessionData('accountBookId');
export const setAccountBookId = (id) => setSessionData('accountBookId', id);
export const getQRCode = () => getSessionData('qrCode');
export const setQRCode = (qrCode) => setSessionData('qrCode', qrCode);
export const getTableNo = () => getSessionData('tableNo');
export const setTableNo = (tableNo) => setSessionData('tableNo', tableNo);
export const getIsQRCodeStatic = () => getSessionData('isQRCodeStatic');
export const setIsQRCodeStatic = (isQRCodeStatic) => setSessionData('isQRCodeStatic', isQRCodeStatic);
export const getTableSessionId = () => getSessionData('tableSessionId');
export const setTableSessionId = (id) => setSessionData('tableSessionId', id);
export const getShareCart = () => getSessionData('shareCart');
export const setShareCart = (shareCart) => setSessionData('shareCart', shareCart);
export const getUseEPayment = () => getSessionData('useEPayment');
export const setUseEPayment = (useEPayment) => setSessionData('useEPayment', useEPayment);
export const getServiceType = () => getSessionData('serviceType');
export const setServiceType = (serviceType) => setSessionData('serviceType', serviceType);
export const getIsPreview = () => getSessionData('isPreview');
export const setIsPreview = (isPreview) => setSessionData('isPreview', isPreview);
export const getIsLandingPageShown = () => getSessionData('isLandingPageShown');
export const setIsLandingPageShown = () => setSessionData('isLandingPageShown', true);

export const removeSessionData = () => sessionStorage.removeItem('sessionData');

const getSessionData = (key) => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));

    if (sessionData !== null) {
        return sessionData[key] ?? null;
    }

    return null;
};

const setSessionData = (key, value) => {
    const sessionData = JSON.parse(sessionStorage.getItem('sessionData')) ?? {};
    sessionData[key] = value;

    sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
};