import React from 'react';
import MenuItemName from 'Menu/components/MenuItemName';
import MenuItemPrice from 'Menu/components/MenuItemPrice';
import MenuItemQuantityIcon from 'Menu/components/MenuItemQuantityIcon';

const MenuItemDetailPanel = React.forwardRef((props, ref) => {
    const { menuItem, fromPrice, qty } = props;

    return (
        <div
            className="d-flex flex-column p-3 bg-white"
            style={{ borderBottom: '1px solid var(--grey-300)' }}
            ref={ref}
        >
            {qty > 0 &&
                <div className="d-flex align-items-center ps-2 pe-3 py-2 mb-3" style={{ background: '#fff6e2', borderRadius: '6px' }}>
                    <div className="col-2 mx-2" style={{ textAlign: 'center' }}>
                        <span className="icon-icon-add-to-basket position-relative text-primary" style={{ fontSize: '40px' }}>
                            <MenuItemQuantityIcon
                                className="position-absolute"
                                style={{ top: '-5px', right: '-6px' }}
                                minWidth='20px'
                                height='20px'
                                border
                                fill
                            >
                                {qty}
                            </MenuItemQuantityIcon>
                        </span>
                    </div>

                    <div className="d-flex flex-column fs-5">
                        <div className="fw-bold">{menuItem.name}</div>
                        <div>Already in your cart.</div>
                    </div>
                </div>
            }

            <div className="d-flex">
                <div className="col-9 d-flex flex-column">
                    <div className="fs-3 fw-bold">
                        <MenuItemName name={menuItem.name} displayCode={menuItem.displayCode} />
                    </div>

                    {menuItem.description &&
                        <div className="fs-6">{menuItem.description}</div>
                    }
                </div>

                <div className="col-3" style={{ textAlign: 'end' }}>
                    <div className="fs-6">from</div>
                    <MenuItemPrice
                        as="div"
                        className="fw-bold"
                        value={fromPrice}
                        isCurrencyFormat={false}
                    />
                </div>
            </div>
        </div>
    );
});

export default React.memo(MenuItemDetailPanel);