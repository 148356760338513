import { pageRoutes } from './routes';
import * as Dialog from 'devextreme/ui/dialog';
import * as DocumentIdConstants from 'data/documentIdConstant';

export function getPageRoute(name) {
    return pageRoutes.find(x => x.name === name);
}

export function getPagePath(name, options) {
    const route = getPageRoute(name);
    let path = route ? route.path : '';

    if (options && path) {
        let queryString = '';

        for (const prop in options) {
            if (options[prop] !== null && options[prop] !== undefined) {
                if (path.indexOf(`:${prop}`) !== -1) {
                    path = path.replace(`:${prop}`, encodeURIComponent(options[prop]));
                } else {
                    queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(options[prop])}`;
                }
            }
        }

        path += queryString;
    }

    return path;
}

export function navigateToPage(history, name, options) {
    const path = getPagePath(name, options);
    history.push(path);
}

export function redirectToPage(path) {
    window.location.href = path;
}

export function redirectToHomePageWithError(options, dialogTitle = null) {
    const path = getPagePath('Home', options);

    if (dialogTitle === null) {
        redirectToPage(path);
    } else {
        const dlg = Dialog.custom({
            showTitle: true,
            title: dialogTitle,
            dragEnabled: false,
            messageHtml: `<div id="${DocumentIdConstants.GeneralReloadMessageBox}" style="max-width:500px">Please click Reload button below to reload.</div>`,
            buttons: [{
                text: 'Reload',
                type: 'default',
                onClick: () => redirectToPage(path)
            }]
        });

        return dlg.show();
    }
}