import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

function withRouter(WrappedComponent) {
    function WithRouter(props) {
        return (
            <WrappedComponent
                {...props}
                location={useLocation()}
                history={useHistory()}
                params={useParams()}
            />
        );
    }

    return WithRouter;
}

export default withRouter;