import React from 'react';

const Image = ({ className, style, imageSrc, width, height, isLazyLoading = false, alternateText = '', ...rest }) => (
    <img
        className={className || undefined}
        style={style || undefined}
        width={width || undefined}
        height={height || undefined}
        loading={isLazyLoading ? 'lazy' : 'eager'} // Note : loading attribute must be defined before src attribute else will have no effect in Firefox
        src={imageSrc}
        alt={alternateText}
        {...rest}
    />
);

export default Image;